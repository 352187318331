@import 'src/custom';

.sidebar-page {
  font-family: GrandParis-Regular;

  .subtitle {
    font-size: 14px;
  }

  .line-name {
    font-family: GrandParis-Medium;
    font-size: 24px;
    margin-bottom: 26px;
    display: flex;
    align-items: center;

    .line-number {
      width: 26px;
      height: 26px;
      font-family: GrandParis-Bold;
      color: $primary;
      background: #fff;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;
      padding: 4px;
      margin-right: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
      float: left;
    }
  }

  .list-view {
    list-style: none;
    padding: 0;

    li {
      overflow: hidden;
      position: relative;
      padding: 0 0 15px 41px;
      font-size: 15px;

      a {
        color: white;
      }

      &::before {
        content: '';
        position: absolute;
        left: 9px;
        top: 21px;
        width: 20px;
        height: 999px;
        border: 1px solid #fff;
        border-width: 0px 0 0 1px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 4px;
        top: 6px;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 2px solid #fff;
        border-radius: 50%;
      }

      &:first-child {
        &::after {
          width: 18px;
          height: 18px;
          left: 1px;
          top: 2px;
        }

        &:before {
          top: 26px;
        }
      }

      &:last-child {
        &:before {
          content: none;
          top: 26px;
        }

        &::after {
          width: 18px;
          height: 18px;
          left: 1px;
          top: 5px;
        }
      }

      .subtitle {
        font-size: 10px;
      }
    }

  }

  .public-transport {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .btn {
    font-size: 14px;

    &:hover {
      background: #fff;
      color: $primary;

    }
  }

  .poi-simulator-title {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-bottom: 3px;
  }

  .simulator-search {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);

  }
}



