@import 'src/custom';

[data-mode="mobile"] {
  .sidebar-page {
    .back-btn {
      font-size: 10px;
    }

    .title {
      font-size: 20px;
    }

    .list-view {
      li {
        font-size: 12px;
        align-items: center;

        &:after {
          width: 8px;
          height: 8px;
        }

        &:before {
          left: 8px;
          top: 19px;
        }

        &:first-child {
          &:after {
            width: 16px;
            height: 16px;
          }
        }

        &:last-child {
          &:after {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .line-name {
      font-size: 18px;
    }
  }
}



