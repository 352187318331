@import "custom";

body {
  font-family: 'GrandParis-Regular';
  background: $white;
  font-size: 0.875rem;

  .cursor-pointer {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  .App {
    height: 100vh;
  }

  .font-size-12 {
    font-size: 0.75rem;
  }

  .font-size-14 {
    font-size: 14px;
  }

  .btn-primary {
    background: var(--primary-color);
    border: 1px solid $white;
    border-radius: 40px;
    font-size: 10px;
    color: white;
    font-family: 'GrandParis-Medium';

    &.btn-calculate {
      padding: 13px 0;
    }

    &.active {
      background: $white;
      color: var(--primary-color);
      border: 1px solid #fff;

      img {
        filter: brightness(0) saturate(100%) invert(14%) sepia(46%) saturate(4097%) hue-rotate(218deg) brightness(104%) contrast(103%);
      }
    }
  }
  .btn-secondary {
    background: #363636;
    border: 1px solid $white;
    border-radius: 40px;
    font-size: 10px;
    color: white;
    font-family: 'GrandParis-Medium';

    &.btn-calculate {
      padding: 13px 0;
    }

    &:hover {
      background: $white;
      color: #363636;
      border: 1px solid #363636;
    }

    &.active {
      background: $white;
      color: #363636;
      border: 1px solid #fff;

      img {
        filter: brightness(0) saturate(100%) invert(14%) sepia(46%) saturate(4097%) hue-rotate(218deg) brightness(104%) contrast(103%);
      }
    }
  }

  .btn-light {
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 40px;
    font-size: 14px;
    color: var(--primary-color);
    font-family: 'GrandParis-Medium';

    &:hover {
      background: var(--primary-color);
      color: $white;
      border: 1px solid var(--primary-color);
    }
  }

  button:focus:not(:focus-visible) {
    outline: none;
    border: none;
    box-shadow: none;
  }

  .custom-checkbox .form-check-input:checked {
    background-color: transparent;
    border-color: #fff;
  }

  .custom-checkbox input[type="checkbox"] {
    position: relative;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    background: transparent;
    cursor: pointer;
    border: 1px dashed #fff;
    border-radius: 2px !important;

    &:checked {
      border-style: solid;
    }
  }

  .form-control:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  .custom-checkbox input[type="checkbox"]::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
    transition: all 0.1s;
    background: #fff;
  }

  .custom-checkbox input[type="checkbox"]:checked::before {
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
  }

  .form-check-input:focus {
    box-shadow: none;
    border-color: #fff;
  }

  .form-control:focus::placeholder {
    color: transparent;
  }

  .title {
    font-size: 30px;
    margin: 6% 0 5%;
    line-height: 140%;
    font-family: RecklessNeue-Regular;
  }

  .error {
    color: #ff0000;
    font-size: 11px;
  }
}

.mapboxgl-ctrl-attrib-inner {
  a:first-child {
    color: #e3670f;
    font-weight: bold;
  }
}

.home {
  .bg-overlay {
    border: 1px solid #0C208F;
    padding-top: 2px !important;

    img {
      filter: brightness(0) saturate(100%) invert(11%) sepia(87%) saturate(2899%) hue-rotate(228deg) brightness(102%) contrast(109%);
    }
  }
}

.mapboxgl-ctrl-group:not(:empty) {
  border-radius: 16px !important;
  border: 1px solid #0C208F;
  box-shadow: none !important;
}
.bg-overlay-compass{
  border: 1px solid #0C208F;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon, .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon, .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
.btn-compass{
  filter: brightness(0) saturate(100%) invert(11%) sepia(87%) saturate(2899%) hue-rotate(228deg) brightness(102%) contrast(109%);
}
.mapboxgl-ctrl-group button+button{
  border-top: 1px solid #0C208F!important;
}


.d3-btn {
  width: 246px;
  height: 45px;
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  padding: 0 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  cursor: pointer;
  color: var(--primary-color);
  transition: 0.2s;
  label{
    margin-bottom: 0;
    margin-right: 5px;
  }
  input{
    border-color: 1px solid var(--primary-color) !important;
  }
  .form-check-input {
    background-color: #fff;
  }
  .form-switch .form-check-input {
    background-color: #fff;
    background-image: none;
  }

  .form-switch .form-check-input {
    width: 2em;
    height: 1em;
    margin-left: -2.5em;
    background-color: #fff; /* White background for the switch */
    background-image: none; /* Remove default background image */
    border-radius: 2em;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.25); /* Optional: adjust the border color */
    transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out;
  }

  .form-switch .form-check-input::before {
    content: '';
    position: absolute;
    top: 0.1em;
    left: 0.1em;
    width: 0.7em;
    height: 0.7em;
    background-color: var(--primary-color); /* Blue (primary color) circle */
    border-radius: 50%;
    transition: transform 0.15s ease-in-out;
  }
  .form-switch .form-check-input:checked::before {
    transform: translateX(1em); /* Move the blue circle to the right */
    background-color: white;
  }
  .form-switch .form-check-input:checked{
    background-color: var(--primary-color);
  }
}
