@import "../../custom";

[data-mode="mobile"] {
  .mobile-wrapper {
    height: 100vh;

    .max-z-index {
      z-index: 1029;
    }

    .page-active {
      z-index: 1028;

      //&:last-child {
      //  z-index: 1031;
      //}
    }

    .page-hidden {
      z-index: -10;
    }

    .sidebar {
      width: 100%;
      display: grid;
      grid-template-rows:  1fr auto;
      overflow-y: scroll;
      font-size: 12px;


      .back-btn {
        margin-top: 4.938rem;
        font-family: 'GrandParis-Regular';
        color: #fff;
        font-size: 0.625rem;
        padding-top: 6%;

        .arrow {
          border-style: solid;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 0.125rem;

          &.left {
            transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
          }
        }
      }

      .tab-content-wrapper {
        min-height: calc(100% - 4.938rem);

        .mobile-filter-wrapper {
          border-top: 1px solid rgba(247, 245, 243, 0.5);
          border-bottom: 1px solid rgba(247, 245, 243, 0.5);
          font-size: 12px;
          font-family: GrandParis-Regular;
          color: #fff;
          padding: 20px 0;
          width: 100%;
          min-height: 60px;
          background: var(--primary-color);
          margin-top: 4.938rem;

        }
      }

      .btn:focus {
        outline: none;
        box-shadow: none;
      }

      main {
        padding: 0;

        .btn-primary {
          background: var(--primary-color);
          border: 1px solid $white;
          border-radius: 40px;
          font-size: 10px;
          color: white;
          font-family: 'GrandParis-Medium';

          &.btn-calculate {
            padding: 13px 0;
          }

          &.active {
            background: $white;
            color: var(--primary-color);
            border: 1px solid #fff;

            img {
              filter: brightness(0) saturate(100%) invert(14%) sepia(46%) saturate(4097%) hue-rotate(218deg) brightness(104%) contrast(103%);
            }
          }
        }

        .accordion {
          overflow: hidden;

          .accordion-button {
            font-size: 12px !important;

            &:after {
              position: relative;
              left: -14px;
            }
          }

          .lines {
            li {
              &:before {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }

    }

    .margin-from-top {
      margin-top: 4.938rem
    }

    .legal-info {
      background: var(--primary-color);
      text-align: center;
      height: 30px;

      span {
        color: #fff;
        font-size: 10px;
        border: none;
        text-align: center;
      }

      .list {
        position: absolute;
        background: #fff;
        border-radius: 10px;
        padding: 30px 31px 20px 31px;
        font-size: 12px;
        max-width: 259px;
        width: 259px;
        z-index: 9;
        bottom: 28px;
        text-align: left;
        left: 50%;
        transform: translate(-50%, 0%);

        .close {
          position: absolute;
          right: 11px;
          color: #ccc;
          top: 9px;
          cursor: pointer;
        }

        li {
          list-style: none;
          padding-bottom: 3%;
          text-decoration: underline
        }

        div {
          font-size: 10px;
          color: #737272;
          line-height: 120%;
        }

      }
    }

    .secondary-layer, .accordion-body {
      input {
        margin-top: 0;
      }
    }

    .legal-info {
      position: relative;
    }

    .mapbox-control-bottom-left{
      position: absolute;
      bottom: 83px;
      right: 48px;
      z-index: 1;
    }
  }
}
