@import "./src/custom";

.journey-view {
  list-style: none;
  padding: 0;
  font-size: 14px;

  li {
    overflow: hidden;
    position: relative;
    padding: 0 0 15px 73px;
    min-height: 77px;
    font-size: 15px;

    background-repeat: no-repeat;
    background-size: 30px 30px;

    &:last-child {
      height: auto;
    }

    .time {
      font-size: 12px;
    }

    &.transport {
      //background-image: url("../../assets/icons/pubic_transport.svg");

      &::before {
        content: '';
        position: absolute;
        left: 42px;
        top: 21px;
        width: 20px;
        height: 999px;
        border: 1px solid #fff;
        border-width: 0px 0 0 1px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 37px;
        top: 6px;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 1px dashed #fff;
        border: 1px solid #fff;
        border-radius: 50%;
      }

      .transport-box {
        > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .icon {
          font-size: 12px;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;

          &.type {
            border: 1px solid #fff;
          }
        }
      }

    }

    &.transport-walk {
      //background-image: url("../../assets/icons/pubic_transport.svg");
      background-size: 30px 30px;
      &::before {
        content: '';
        position: absolute;
        left: 42px;
        top: 21px;
        width: 20px;
        height: 999px;
        border: 1px dashed #fff;
        border-width: 0px 0 0 1px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 37px;
        top: 6px;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 1px dashed #fff;
        border-radius: 50%;
      }
    }

    &.walk {
      background-image: url("../../assets/icons/street-journey.svg");
      background-size: 34px 23px;



      &::before {
        content: '';
        position: absolute;
        left: 42px;
        top: 21px;
        width: 20px;
        height: 999px;
        border: 1px dashed #fff;
        border-width: 0px 0 0 1px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 37px;
        top: 6px;
        width: 10px;
        height: 10px;
        background: #fff;
        border: 1px dashed #fff;
        border-radius: 50%;
      }
    }

    a {
      color: white;
    }


    &:first-child {
      &::after {
        width: 16px;
        height: 16px;
        left: 34px;
        top: 2px;
      }

      &:before {
        top: 24px;
      }
    }

    &:last-child {
      &:before {
        content: none;
        top: 24px;
      }

      &::after {
        width: 16px;
        height: 16px;
        left: 34px;
        top: 5px;
      }
    }

    .transport-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      span {
        margin-bottom: 2px;
      }
    }
  }

  img {
    width: 20px;
    height: 20px;
    filter: brightness(0) invert(1);
  }
}

.transport-name {
  font-size: 14px;
  line-height: 180%;
}

.percent {
  font-size: 16px;
  font-family: GrandParis-Regular;
  display: flex;
  justify-content: end;
  align-items: center;
}

.avec {
  &::before {
    content: ' ';
    display: inline-block;
    width: 23px;
    height: 6px;
    background: #039DD1;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    margin-right: 16px;
    position: relative;
    top: -2px;
  }
}


.sans {
  &::before {
    content: ' ';
    display: inline-block;
    width: 23px;
    height: 6px;
    background: #D7354D;
    border: 1px solid #FFFFFF;
    border-radius: 3px;
    margin-right: 16px;
    position: relative;
    top: -2px;
  }
}

.progress {
  background-color: var(--primary-color);
  height: 10px;
  display: flex;
  border-radius: 5px;
  margin-top: 5px;

  .progress-bar {
    background-color: #fff;
  }
}


