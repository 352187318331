@import "../../../../custom";

[data-mode="mobile"] {
  .calendar {
    width: 100%;
    height: 136px;
    left: 0;
    position: absolute;
    margin-top: 4.8rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #F7F5F3;
    padding: 0 20px 15px 20px;
    color: var(--primary-color);
    font-size: 0.75rem;

    p {
      margin-top:18px ;
    }

    &:before {
      content: '';
      border-bottom: 1px solid var(--primary-color);
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    #date-slider {
      display: flex;
      flex-direction: row;
      align-content: stretch;
      position: relative;
      width: 100%;
      height: 55px;
      user-select: none;

      &::before {
        content: " ";
        position: absolute;
        height: 1.5px;
        width: calc(100% * (6 / 7));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-image: linear-gradient(to right, var(--primary-color) 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }

      input, label {
        box-sizing: border-box;
        flex: 1;
        user-select: none;
        cursor: pointer;
      }

      label {
        display: inline-block;
        position: relative;
        width: 20%;
        height: 100%;
        user-select: none;

        &.active {
          &:after {
            background: var(--primary-color);
          }
        }

        &::before {
          content: attr(data-date);
          text-transform: capitalize;
          position: absolute;
          left: 50%;
          // padding-top: 15px;
          transform: translate(-50%, -15px);
          white-space: break-spaces;
          transition: all 0.15s ease-in-out;

          font-family: 'GrandParis-Regular';
          font-size: 0.75rem;
          font-weight: 300;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          bottom: 18px;
        }

        &::after {
          content: " ";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 11px;
          height: 11px;
          border: 1px solid var(--primary-color);
          background: #fff;
          border-radius: 50%;
          pointer-events: none;
          user-select: none;
          z-index: 1;
          cursor: pointer;
          transition: all 0.15s ease-in-out;
        }

        &:hover::after {
          transform: translate(-50%, -50%) scale(1.25);
        }
      }

      input {
        display: none;
        background: var(--primary-color);
        height: 11px;
        width: 11px;

        &:checked {
          + label::before {
            font-family: "GrandParis-Bold";
            font-weight: 500;
            opacity: 1;
          }

          + label::after {
            width: 19px;
            height: 19px;
          }

          + label:hover::after {
            transform: translate(-50%, -50%);
          }

          ~ #date-pos {
            opacity: 1;
          }

          @for $i from 1 through 7 {
            &:nth-child(#{$i * 2 - 1}) ~ #date-pos {
              left: #{($i * 14.2858%) - 7.1428%};
            }
          }
        }
      }
    }

    #date-pos {
      display: block;
      position: absolute;
      top: 50%;
      width: 11px;
      height: 11px;
      background: var(--primary-color);
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: 2;
    }

    .calendar-close {
      position: absolute;
      top: 23px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url('../../../../assets/icons/close.svg') no-repeat;
      filter: var(--filter-svg-color);
      cursor: pointer;
    }

  }
}
