.AppInfoPage {
  h5 {
    font-size: 1.25rem;

    ~ div {
      text-align: center;

      img {
        max-height: 250px;
      }
    }
  }

  .app-logo {
    max-width: 100%;
    max-height: calc(100vh - 52vh);
  }
}

@media (min-width: 850px) {
  .modal-dialog {
    margin-right: auto !important;
    margin-left: 28px !important;
  }
}
