@import 'src/custom';

main {
  flex: 1;
  overflow: auto;
  padding: 0 7% 0;
  color: #fff;
  margin-top: 1rem;

  .menu-tab {
    padding-bottom: 6%;
  }

  .accordion {
    .accordion-item {
      background-color: transparent;
      border-bottom: 1px solid #fff;
      color: #fff;
      border-top: none;
      border-right: none;
      border-left: none;
      border-radius: 0;

      .accordion-button {
        padding: 4% 0;
      }

      .accordion-body {
        padding: 0 0 5% 0;
      }

      .accordion-button {
        background: transparent;
        color: white;
        font-size: 14px;

        &:after {
          content: '';
          background-image: url("../../../assets/icons/toggle-arrow.svg");
          color: #fff;
          transform: none;
          width: 11px;
          height: 11px;
          background-size: 11px;
        }

        &:not(.collapsed) {
          box-shadow: none;

          &::after {
            transform: rotate(180deg);
          }
        }
      }

      .lines {
        position: relative;
        list-style: none;
        margin-left: 0;
        padding-left: 1.2em;

        li {
          padding-left: 21px;
          margin-bottom: 15px;

          &:before {
            content: '';
            background-image: url('../../../assets/icons/picto_svg/Métro.svg');
            background-size: cover;
            width: 22px;
            height: 22px;
            position: absolute;
            left: 0;
          }
        }
      }
    }
  }

  .secondary-layer {
    padding-bottom: 3%;

    p {
      padding: 3% 0;
      margin: 0;
    }
  }
}

.sidebar {
  position: absolute;
  width: 31%;
  transition: transform 0.5s;
  z-index: 1033;
  left: 0;
  height: 100%;
  min-width: 363px;
  cursor: pointer;
}

.flex-center.left {
  left: 0px;
}

.left.collapsed {
  transform: translateX(-94%);
}
