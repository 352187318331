[data-mode="mobile"] {
  .popup-bottom {
    position: relative;

    .popup-close {
      position: absolute;
      display: block;
      top: 23px;
      right: 12px;
      width: 24px;
      height: 24px;
      background: url('../../../../assets/icons/close.svg') no-repeat;
      cursor: pointer;
    }
  }
}
