.sidebar__toggle {
  display: flex;
  align-items: center;
  width: 6%;
  justify-content: center;
  right: 4px;
  &:after {
    content: '';
    display: inline-block;
    width: 1.4em;
    height: 1.4em;
    border-top: 0.4em solid #FFFFFF;
    border-right: 0.4em solid #FFFFFF;
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}
