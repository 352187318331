@import "../../../custom";

.StreetViewModal {
  aside {
    .btn {
      margin-right: 4%;
      margin-bottom: 20px;
      border: 1px solid $primary;
      background: $primary;
      border-radius: 50%;
      width: 48px;
      height: 48px;

      &::before {
        content: url('../../../assets/icons/back-arrow.svg');
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        background: #fff;

        &::before {
          filter: brightness(0) saturate(100%) invert(14%) sepia(46%) saturate(4097%) hue-rotate(218deg) brightness(104%) contrast(103%);
        }
      }
    }
  }
}

[data-mode="mobile"] {
  .StreetViewModal {
    aside {
      .btn {
        width: 30px;
        height: 30px;
      }
    }
  }
}



