@import "../../../custom";

.layers-menu-btn {
  width: 235px;
  height: 45px;
  border-radius: 30px;
  border: 1px solid var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  cursor: pointer;
  color: var(--primary-color);
  transition: 0.2s;

  &.disabled {
    border: 1px solid var(--primary-color);
  }

  &:hover {
    background: var(--primary-color);
    color: #fff;
    border: 1px solid var(--primary-color);

    span {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(284deg) brightness(104%) contrast(104%);
    }
  }

  &.active {
    transition: 0.2s;
    background: var(--primary-color);
    color: $white;
    border: 1px solid var(--primary-color);

    span {
      filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(327deg) brightness(104%) contrast(101%);
    }
  }

  span {
    height: 18px;
    width: 18px;
    background: url('../../../assets/icons/info.svg') no-repeat center;
    filter: var(--filter-svg-color);
  }

  p {
    font-family: 'GrandParis-Regular';
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;

    margin: 0;
  }
}

.layers-menu {
  width: 289px;
  position: absolute;
  margin-top: 21px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 27px;
  color: var(--primary-color);

  p {
    margin: 0;
  }

  &__section {
    margin-top: 30px;

    &:first-child {
      margin: 0;
    }
  }

  &__layer {
    display: flex;
    align-items: center;
    margin-top: 10px;

    input[type="checkbox"] {
      appearance: none!important;
      border: 1px dashed #222222!important;
      border-radius: 2px!important;

      &:checked {
        border: 1px solid #222222;

        &:before {
          background: #000;
        }
      }
    }

    label {
      font-family: GrandParis-Regular;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #222222;
      margin-left: 12px;
    }
  }

  .layers-menu-close-btn {
    background-color: #fff;
    background-position: center center;
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 12px;
    background-size: 11px;
    cursor: pointer;
    border-radius: 50%;
    width: 14px;
    height: 14px;

    .layers-menu__close {
      background-color: var(--primary-color);
      -webkit-mask-image: url('../../../assets/icons/close-blue.svg');
      mask: url('../../../assets/icons/close-blue.svg') no-repeat center center;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 7px;
      right: 7px;
    }

    &:hover {
      background-color: var(--primary-color);

      .layers-menu__close {
        background-color: #fff;
      }
    }
  }
}

