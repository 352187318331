@import "../../../custom.scss";

.filter-wrapper {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  margin-left: 34%;
  margin-top: 20px;
  transition: 300ms ease-in-out;
}

.mapbox-control-bottom-left{
  position: absolute;
  bottom: 60px;
  right: 48px;
  z-index: 1;
}
.btn-3d{
  border: 1px solid #0c208f!important;
  }

.main-content {
  position: absolute; top: 0; bottom: 0; width: 100%;
  &--expanded {
    .filter-wrapper {
      margin-left: 4%;
    }
  }
}

#custom-popup {
  position: absolute;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  display: none; /* Start hidden */
  z-index: 10; /* Ensure it's above other map elements */
  background: white; /* Optional: for better visibility */
}
