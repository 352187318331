.CustomControl {
  width: 32px;
  height: 32px;
  display: block;
  outline: none;
  border: none;
  box-sizing: border-box;
  background-color: white;
  cursor: pointer;
  border-radius: 16px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #666666;
}
.CustomControl .bg-overlay {
  width: 32px;
  height: 32px;
  display: block;
  outline: none;
  border: none;
  box-sizing: border-box;
  border-radius: 16px;
  padding-top: 4px;
}
.CustomControl .bg-overlay:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.CustomControl .bg-overlay > img {
  width: 24px;
  height: 24px !important;
  margin: auto;
}

.bg-overlay-compass {
    position: relative;
    width: 48px;
    height: 48px;
    left: -10px;
    outline: none;
    background-position: 0 0;
    -webkit-user-select: none;
    user-select: none;
    top: -14px;
    background-color: white;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #666666;
}

.btn-compass {
    background: transparent url('../../../assets/compass.png') 0 0 no-repeat;
    background-size: 154px 48px;
    transition: transform .7s ease-in-out;
}

.compass-btn {
  cursor: pointer;
  background-position: -48px -4px;
  border-radius: 50%;
  position: absolute;
  width: 12px;
  height: 40px;
  left: 18px;
  top: 4px;
}

.arrow-left {
    cursor: pointer;
    background-position: -109px 0;
    position: absolute;
    width: 13px;
    height: 48px;
    left: 2px;
    z-index: 1;
}

.arrow-right {
    cursor: pointer;
    background-position: -109px 0;
    position: absolute;
    width: 13px;
    height: 48px;
    right: 2px;
    z-index: 1;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.mapbox-gl-draw_ctrl-draw-btn.active{
    filter: invert(52%) sepia(5%) saturate(4603%) hue-rotate(143deg) brightness(104%) contrast(76%);
}
