header {
  padding: 3% 0 5% 0;
  position: relative;
  .arrow {
    border-style: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 0.125rem;

    &.left {
      transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
    }
  }

  .back-btn {
    font-family: 'GrandParis-Regular';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1%;
    color: #fff;
    font-size: 0.625rem;
    padding-left: 9%;
  }

  .logo {
    width: 27%;
    max-width: 27%;
    margin: 0 auto;
    position: relative;

    img {
      width: 8.813rem;
    }
  }

}
