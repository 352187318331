.loading-1 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
}

.loading-1 i {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 9px;
  height: 5px;
  background: #fff;
  animation: loading-1 1.5s infinite ease-in-out;
}

.loading-1 i:nth-child(2) {
  left: 11px;
  animation-delay: .2s;
}

.loading-1 i:nth-child(3) {
  left: 22px;
  animation-delay: .4s;
}

.loading-1 i:nth-child(4) {
  left: 33px;
  animation-delay: .6s;
}

.loading-1 i:nth-child(5) {
  left: 44px;
  animation-delay: .8s;
}

@keyframes loading-1 {
  0% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
  25% {
    height: 30px;
    transform: translateY(15px);
    background: var(--background-color);
  }
  50% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
  100% {
    height: 5px;
    transform: translateY(0px);
    background: #fff;
  }
}
