@import "../../custom.scss";

$color: var(--primary-color);


[data-mode="desktop"], [data-mode="tablet"] {

  .search-label {
    position: relative;

    &:hover {
      &:before {
        background-size: 18px;
        border-radius: 30px;
        filter: brightness(0) invert(1);;
      }
    }

    &:focus-within {
      &:before {
        filter: brightness(0) invert(1);
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 40px;
      background: url("../../assets/icons/search.svg") no-repeat left 12px center;
      filter: var(--filter-svg-color);
      box-sizing: border-box;
    }

    input {
      //padding: 10px 30px;
      padding: 5px 0 5px 40px;
      width: 0;
      font-family: 'GrandParis-Regular';
      color: #fff;
      border-radius: 30px;
      border: 1px solid var(--primary-color);
      height: 45px;
      transition: width 0.5s, background 0.3s ease, border 0.3s;

      &:hover {
        background-color: var(--primary-color);
      }

      &.disabled {
        color: var(--bs-btn-disabled-color);
        pointer-events: none;
        background-color: var(--bs-btn-disabled-bg);
        border: 1px solid var(--primary-color);
        opacity: var(--bs-btn-disabled-opacity);
      }

      &:focus {
        z-index: 3;
        width: 14.938rem;
        border: 1px solid var(--primary-color);
        outline: none;
        cursor: auto;
        padding-right: 10px;
        background-color: var(--primary-color);

        &::placeholder {
          color: #fff !important;
        }

      }
    }
  }


  //.search{
  //  font-family: 'GrandParis-Regular';
  //  color: #fff;
  //  position: relative;
  //  z-index: 5;
  //  transition: width 0.5s, background 0.3s ease, border 0.3s;
  //  height: 45px;
  //  width: 0;
  //  margin: 0;
  //  padding: 5px 0 5px 40px;
  //  box-sizing: border-box;
  //  font-size: 14px;
  //  cursor: pointer;
  //  border-radius: 30px;
  //  border: 1px solid var(--primary-color);
  //  background: url('../../assets/icons/search.svg') no-repeat left 12px center #fff;
  //  background-size: 18px;
  //  &.disabled{
  //    color: var(--bs-btn-disabled-color);
  //    pointer-events: none;
  //    background-color: var(--bs-btn-disabled-bg);
  //    border: 1px solid var(--primary-color);
  //    opacity: var(--bs-btn-disabled-opacity);
  //  }
  //
  //  &:hover {
  //    background: url('../../assets/icons/search-white.svg') no-repeat left 12px center var(--primary-color);
  //    border: 1px solid #fff;
  //    background-size: 18px;
  //  }
  //
  //  &:focus {
  //    z-index: 3;
  //    width: 14.938rem;
  //    border: 1px solid var(--primary-color);
  //    outline: none;
  //    cursor: auto;
  //    padding-right: 10px;
  //    background: url('../../assets/icons/search-white.svg') no-repeat left 12px center var(--primary-color);
  //
  //    &::placeholder {
  //      color: #fff !important;
  //    }
  //
  //  }
  //}

  .autocomplete {
    width: 100%;
    position: absolute;
    //top: 60px;
    margin-top: 21px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    //margin-left: calc(4% + 63px);
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    color: var(--primary-color);
    max-height: calc(100vh - 50vh);
    overflow-x: auto;

    li {
      color: #000;
      margin: 0 !important;
      width: 100%;
      padding: 10px 27px;
      font-size: 12px;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

[data-mode="mobile"] {
  .search-label{
    width: 100%;
  }
  .search {
    font-family: 'GrandParis-Regular';
    padding: 0.313rem 0 0.313rem 1.5rem;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid var(--primary-color);
    border-bottom: none;
    border-radius: 30px;
    width: 100% !important;
    margin-top: 20px;
    font-size: 12px;
    height: 40px;

    &.open {
      border-radius: 20px 20px 0 0;

      &:focus {
        background-color: #FFFFFF;
      }
    }

    &:hover {
      background-size: 18px;
    }

    &:focus {
      z-index: 3;
      width: 14.938rem;
      background-color: #E7E9F4;
      outline: none;
      cursor: auto;
      padding-right: 10px;

      &::placeholder {
        color: #666 !important;
      }

    }
  }

  .autocomplete {
    width: 100%;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    border-radius: 0 0 20px 20px;
    color: var(--primary-color);
    max-height: calc(100vh - 50vh);
    overflow-x: auto;
    border: 1px solid var(--primary-color);
    border-top: none;

    &:before {
      content: '';
      border-bottom: 1px solid #ccc;
      width: 88%;
      margin: 0 auto;
    }

    li {
      color: #000;
      margin: 0 !important;
      width: 100%;
      padding: 10px 27px;
      font-size: 12px;

      &:first-child {
        border-radius: 10px 10px 0 0;
      }

      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
