@import "../../custom";

[data-mode="desktop"], [data-mode="tablet"] {
  .calendar-btn {
    width: 166px;
    height: 45px;
    border-radius: 30px;
    border: 1px solid var(--primary-color);
    padding: 0 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    cursor: pointer;
    color: var(--primary-color);
    transition: 0.2s;

    &:hover {
      background: var(--primary-color);
      color: #fff;

      span {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(284deg) brightness(104%) contrast(104%);
      }
    }

    &:active {
      border: 1px solid #fff;
    }

    &.active {
      transition: 0.2s;
      background: var(--primary-color);
      color: $white;

      span {
        filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(327deg) brightness(104%) contrast(101%);
      }
    }

    span {
      height: 18px;
      width: 18px;
      background: url('../../assets/icons/calendar.svg') no-repeat center;
      filter: var(--filter-svg-color);
    }

    p {
      font-family: 'GrandParis-Regular';
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;

      margin: 0;
    }
  }

  .calendar {
    max-width: 523px;
    width: 100%;
    height: 136px;
    left: 0;
    position: absolute;
    margin-top: 21px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #fff;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    padding: 20px 20px 15px 20px;
    color: var(--primary-color);

    & > p {
      padding-left: 15px;
    }

    #date-slider {
      display: flex;
      flex-direction: row;
      align-content: stretch;
      position: relative;
      width: 100%;
      height: 55px;
      user-select: none;

      &::before {
        content: " ";
        position: absolute;
        height: 1.5px;
        width: calc(100% * (6 / 7));
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-image: linear-gradient(to right, var(--primary-color) 33%, rgba(255, 255, 255, 0) 0%);
        background-position: bottom;
        background-size: 10px 1px;
        background-repeat: repeat-x;
      }

      input, label {
        box-sizing: border-box;
        flex: 1;
        user-select: none;
        cursor: pointer;
      }

      label {
        display: inline-block;
        position: relative;
        width: 20%;
        height: 100%;
        user-select: none;

        &.active {
          &:after {
            background: var(--primary-color);
          }
        }

        &::before {
          content: attr(data-date);
          text-transform: capitalize;
          position: absolute;
          left: 50%;
          // padding-top: 15px;
          transform: translate(-50%, -15px);
          white-space: break-spaces;
          transition: all 0.15s ease-in-out;

          font-family: 'GrandParis-Regular';
          font-size: 14px;
          font-weight: 300;
          line-height: 17px;
          letter-spacing: 0em;
          text-align: center;
          bottom: 18px;

        }

        &::after {
          content: " ";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 11px;
          height: 11px;
          border: 1px solid var(--primary-color);
          background: #fff;
          border-radius: 50%;
          pointer-events: none;
          user-select: none;
          z-index: 1;
          cursor: pointer;
          transition: all 0.15s ease-in-out;
        }

        &:hover::after {
          transform: translate(-50%, -50%) scale(1.25);
        }
      }

      input {
        display: none;
        background: var(--primary-color);
        height: 11px;
        width: 11px;

        &:checked {
          + label::before {
            font-family: "GrandParis-Bold";
            font-weight: 500;
            opacity: 1;
          }

          + label::after {
            width: 19px;
            height: 19px;
          }

          + label:hover::after {
            transform: translate(-50%, -50%);
          }

          ~ #date-pos {
            opacity: 1;
          }

          @for $i from 1 through 7 {
            &:nth-child(#{$i * 2 - 1}) ~ #date-pos {
              left: #{($i * 14.2857%) - 7.1428%};
            }
          }
        }
      }
    }

    #date-pos {
      display: block;
      position: absolute;
      top: 50%;
      width: 11px;
      height: 11px;
      background: var(--primary-color);
      border-radius: 50%;
      transition: all 0.15s ease-in-out;
      transform: translate(-50%, -50%);
      opacity: 0;
      z-index: 2;
    }
.calendar-close-btn{
  background-color: #fff;
  background-position: center center ;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 12px;
  background-size: 11px;
  cursor: pointer;
  border-radius: 50%;
  width: 14px;
  height: 14px;

  .calendar-close {
    background-color: var(--primary-color);
    -webkit-mask-image: url('../../assets/icons/close-blue.svg');
    mask : url('../../assets/icons/close-blue.svg') no-repeat center center;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 7px;
    right: 7px;
  }

  &:hover{
    background-color: var(--primary-color);
    .calendar-close{
      background-color: #fff;
    }
  }
}

  }
}
