@import "../../custom";

[data-mode="desktop"], [data-mode="tablet"] {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white;
  }
  aside {
    transition: all 200ms linear;

    .text-muted {
      color: #9b9b9b !important;
    }
  }

  .MapPopup {
    max-height: 300px;
    overflow: hidden;
  }

  .wrapper {
    //display: flex;
    //align-items: stretch;

    .btn:focus {
      outline: none;
      box-shadow: none;
    }

    .btn-primary {
      background: var(--btn-background-color);
      border: 1px solid $white;
      border-radius: 40px;
      font-size: 14px;
      color: white;
      font-family: 'GrandParis-Medium';

      &:hover {
        background: $white;
        color: var(--btn-background-color);
        border: 1px solid #fff;

        img {
          filter: var(--filter-svg-color);
        }
      }

      &.btn-calculate {
        padding: 13px 0;
      }

      &.active {
        background: $white;
        color: var(--btn-background-color);
        border: 1px solid #fff;

        img {
          filter: var(--filter-svg-color)
        }
      }
    }
  }
}

.filter-dropdown {
  &.scrollable {
    max-height: 400px;
    overflow: auto;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}

[data-mode="tablet"] {
  //.main-content {
  //  //margin-left: 363px;
  //
  //  &--expanded {
  //    margin-left: 0;
  //  }
  //}
}
