@import "../../../custom";

.mapboxgl-popup-close-button {
  color: $primary;
  font-size: 0;
  top: 9px;
  right: 9px;
  position: absolute;
  padding: 12px;
  width: 25px;
  height: 25px;
  background: #fff url('../../../assets/icons/close-blue.svg') no-repeat center center;
  background-size: 11px;
  border-radius: 50%;

  &:hover {
    background: $primary url('../../../assets/icons/close.svg') no-repeat center center;
    background-size: 11px;
  }
}

.secondary-layer-popup {
  padding: 0.25rem;
}

.mapboxgl-popup {
  max-width: 414px !important;
}

.mapboxgl-popup-content {
  border: 1px solid $primary;
  border-radius: 10px;

}

.mapboxgl-popup-tip {
  display: none;
}


.secondary-layer-popup {
  font-size: 14px;
  font-family: 'GrandParis-Regular';

  .btn {
    background: #fff;
  }
}

[data-mode="mobile"] {
  .secondary-layer-popup {
    width: 100%;
    left: 0;
    position: absolute;
    margin-top: 4.8rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    background: #F7F5F3 !important;
    padding: 3px 20px 15px 20px;
    color: #000;
    font-size: 12px;
    top: 0;

    .secondary-close{
      display: block;
    }

    &:before {
      content: '';
      border-bottom: 1px solid $primary;
      width: 100%;
      margin: 0 auto;
      position: relative;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  .secondary-close {
    position: absolute;
    top: 23px;
    right: 12px;
    width: 24px;
    height: 24px;
    background: url('../../../assets/icons/close.svg') no-repeat;
    filter: brightness(0) saturate(100%) invert(14%) sepia(46%) saturate(4097%) hue-rotate(218deg) brightness(104%) contrast(103%);
    cursor: pointer;
  }
  .secondary-button{
    width: 60%;
    margin: 0 auto;
  }
}


