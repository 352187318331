@import "../../../../custom";

[data-mode="mobile"] {
  .layers-box {
    margin-top: 4.938rem;

    .layers-menu {
      margin-top: 0;
      width: 100%;
      border-radius: 0;
      border: none;
      background: #F7F5F3;
      font-size: 0.75rem;
      padding-top: 0;

      label {
        font-size: 0.75rem;
      }

      &__close {
        background: url('../../../../assets/icons/close.svg') no-repeat;
        filter: var(--filter-svg-color);
        top: 23px;
        right: 17px;
        width: 24px;
        height: 24px;
        border-radius: 0;
        position: absolute;
        padding: 12px;
        background-size: 11px;
        cursor: pointer;

      }

      &:before {
        content: '';
        border-bottom: 1px solid $primary;
        width: 100%;
        margin: 0 auto;
        position: relative;
        z-index: 1029;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
