@import "../../custom";

.simulator {
  .title {
    font-family: RecklessNeue-Regular, serif;
  }

  .form-label {
    font-size: 11px;
    padding: 0 25px;
    font-family: GrandParis-Medium, serif;
  }

  .input-group {
    background-repeat: no-repeat;
    padding-left: 1.25rem;
    height: 64px;

    &.inactive {
      background-image: url("data:image/svg+xml,%3Csvg width='99%25' height='100%25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Crect x='0' y='1' width='99%25' height='60' rx='30' stroke='white' stroke-linecap='round' stroke-dasharray='4 8'/%3E%3C/g%3E%3C/svg%3E");

      &:after {
        content: url("../../assets/img/input_border.svg");
        position: absolute;
        top: -9px;
        right: 53px;
      }

    }

    &.error-input {
      background-image: url("data:image/svg+xml,%3Csvg width='99%25' height='100%25' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Crect x='0' y='1' width='99%25' height='60' rx='30' stroke='red' stroke-linecap='round' stroke-dasharray='4 8'/%3E%3C/g%3E%3C/svg%3E");

      &:after {
        content: url("../../assets/img/input_border-error.svg");
        position: absolute;
        top: -9px;
        right: 53px;
      }

      img {
        filter: brightness(0) saturate(100%) invert(27%) sepia(85%) saturate(7126%) hue-rotate(355deg) brightness(93%) contrast(118%);
      }

      input {
        color: red;
      }
    }

    &.chosen {
      background-image: url("data:image/svg+xml,%3Csvg width='99%25' height='100%25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Crect x='1' y='1' width='99%25' height='60' rx='30' stroke='white' stroke-linecap='round' stroke-dasharray='0 0'/%3E%3C/g%3E%3C/svg%3E");

      &:after {
        content: url("../../assets/img/input_border.svg");
        position: absolute;
        top: -9px;
        right: 53px;
      }
    }


    &.active {
      background: #fff;
      border-radius: 30px 30px 0 0;

      input {
        color: #737272 !important;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        width: 84%;
        border-bottom: 1px solid #E9E9E9;

      }

      span {

        img {
          filter: var(--filter-svg-color);
        }
      }
    }

    .input-div {
      margin: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 86%;
      display: flex;

      .input-group-text {
        border: none
      }
    }

    input {
      font-size: 14px;
      color: #fff;
      border: none;
      width: 100%;

      &:focus-visible {
        outline: none;
      }

      &::placeholder {
        color: #fff;
        opacity: 1;
      }
    }
  }

  ul {
    background: #fff;
    border-radius: 0 0 30px 30px;
    color: #000;
    padding: 1rem 1.5rem 1.5rem;
    z-index: 1;
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    font-size: 13px;

    li {
      padding: 4px 0;
      font-family: GrandParis-Regular;

      tr {
        &:hover {
          color: var(--primary-color);
          filter: var(--filter-svg-color);
        }
      }

      img {
        width: 16px;
        margin-right: 2px;
        filter: brightness(0%);
      }

    }
  }

  .error-text {
    font-size: 0.625rem;
    color: red;
    position: absolute;
    background: #fff;
    border-radius: 5px;
    margin-left: 1.25rem;
  }


  .switch-button {
    justify-content: end !important;
    height: 2.563rem;
    position: relative;

    span {
      float: right;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid var(--primary-color);
      border-radius: 50%;

      &:after {
        content: url('../../assets/icons/switch-btn-white.svg');
        filter: var(--filter-svg-color);
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 2px
      }

      &:hover {
        border: 1px solid #fff;
        background-color: var(--primary-color);

        &:after {
          filter: brightness(0) invert(1);
        }
      }
    }
  }


  .btn-calculate {
    margin-top: 7%;

    &:hover {
      background: #fff;
      color: var(--primary-color);
    }
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
  }
}

[data-mode="mobile"] {
  .simulator {
    ul {
      max-height: 196px;
    }

    .switch-button {
      span {
        display: none;
      }
    }

    .input-group {
      &.inactive, &.chosen, &.error-input {
        &:after {
          top: -6px;
        }
      }
    }
  }


}
