.AppInfoModal.modal {
  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    border-bottom: 0 solid #dee2e6;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .modal-body {
    padding: 0 1rem 0 1rem;
  }

  .modal-footer {
    .btn {
      width: 40%;
      font-size: 0.8rem;
    }
  }
}
