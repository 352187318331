footer {
  background: #0A1C7B;
  font-size: 10px;
  color: #fff;
  padding: 7px 7% 7px;

  .collapsable-tab {
    cursor: pointer;
  }

  .col {
    position: relative;

    .legal-information {
      position: absolute;
      inset: auto auto 0 auto;
      margin: 0;
      transform: translate3d(0px, -34px, 0px);
      background: #fff;
      border-radius: 10px;
      padding: 12% 13% 8%;
      font-size: 14px;
      max-width: 259px;
      width: 259px;
      z-index: 9;
      max-height: 246px;
      height: 246px;
      right: 0;

      .close {
        position: absolute;
        right: 11px;
        color: #ccc;
        top: 9px;
        cursor: pointer;
      }

      li {
        list-style: none;
        padding-bottom: 3%;
      }

      div {
        font-size: 10px;
        color: #737272;
        line-height: 120%;
      }

    }
  }
}
