@import "custom";

@font-face {
  font-family: 'GrandParis-RegularItalic';
  src: url('./assets/fonts/GrandParis-RegularItalic.otf');
  src: url('./assets/fonts/GrandParis-RegularItalic.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-RegularItalic.woff') format('woff2'),
  url('./assets/fonts/GrandParis-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GrandParis-BoldItalic';
  src: url('./assets/fonts/GrandParis-BoldItalic.otf');
  src: url('./assets/fonts/GrandParis-BoldItalic.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-BoldItalic.woff') format('woff'),
  url('./assets/fonts/GrandParis-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'GrandParis-Bold';
  src: url('./assets/fonts/GrandParis-Bold.otf');
  src: url('./assets/fonts/GrandParis-Bold.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-Bold.woff') format('woff'),
  url('./assets/fonts/GrandParis-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GrandParis-Medium';
  src: url('./assets/fonts/GrandParis-Medium.otf');
  src: url('./assets/fonts/GrandParis-Medium.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-Medium.woff') format('woff'),
  url('./assets/fonts/GrandParis-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GrandParis-Regular';
  src: url('./assets/fonts/GrandParis-Regular.ttf');
  src: url('./assets/fonts/GrandParis-Regular.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-Regular.woff') format('woff'),
  url('./assets/fonts/GrandParis-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RecklessNeue-Light';
  src: url('./assets/fonts/RecklessNeue-Light.otf');
  src: url('./assets/fonts/RecklessNeue-Light.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RecklessNeue-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RecklessNeue-Regular';
  src: url('./assets/fonts/RecklessNeue-Regular.otf');
  src: url('./assets/fonts/RecklessNeue-Regular.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/RecklessNeue-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GrandParis-lgt';
  src: url('./assets/fonts/GrandParis-Light.otf');
  src: url('./assets/fonts/GrandParis-Light.otf?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GrandParis-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html, body, #root {
  margin: 0;
  height: 100%;
  user-select: none;
  color: #212529;
  overflow: hidden;
}

//:global {
.no-chevron {
  &:after {
    content: '';
    border: none;
    margin: 0;
  }
}

.POIPrintPreview {
  display: none;
}

.printing-active {
  .POIPrintPreview {
    display: block;
  }

  .container {
    width: auto;
  }
}

button {
  svg {
    fill: currentColor;
  }
}

//}
