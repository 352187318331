@import 'src/custom';

[data-mode="mobile"] {
  .poi {
    header {
      &:after{
        content: '';
        position: absolute;
        bottom:0;
        border-bottom: 1px solid #fff;
        width: 94%;
        height: 1px;
      }
    }
  }

  header {
    height: 4.938rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0;

    .back-btn-url {
      font-family: 'GrandParis-Regular';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      padding-left: 0;
      left: auto;
      font-size: 10px
    }

    &.bgWhite {
      background: #F7F5F3;
    }

    &.bgPrimary {
      background: var(--primary-color);
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      img {
        width: auto;
        height: 3.175rem;
      }
    }
  }
}

[data-mode="tablet"] {
  header {
    font-size: 16px;
  }
}
